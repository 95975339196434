import React from "react";
import { useSelector } from "react-redux";
import { DispatchTrackingEvents, getAllEventTrackers } from "../../analytics";
import { isEmptyObj } from "../../Utils";
// Catch: If you name an import as useUser, then react will give a compile time error
// Error: useUser can't be used inside a callback.

const WithTracker = (WrappedComponent) => {
  return (props) => {
    // Before dispatching the events to events handler, append user properties to the events object
    const dispatchTrackingEvent = (trackingDetails) => {
      const userProperties = getUserProperties();
      const updatedTrackingObject = {
        name: trackingDetails.name,
        properties: {
          ...(trackingDetails.properties || {}),
          ...userProperties,
        },
        options: trackingDetails.options || {},
      };
      DispatchTrackingEvents(updatedTrackingObject);
    };

    const allEventTrackers = getAllEventTrackers();

    const getTrackingEventActions = () => {
      return Object.keys(allEventTrackers).reduce(
        (processedEventTrackers, currentTrackerScreenKey) => {
          return {
            ...processedEventTrackers,
            [currentTrackerScreenKey]: allEventTrackers[
              currentTrackerScreenKey
            ](applicationContexts, dispatchTrackingEvent),
          };
        },
        {}
      );
    };

    const getUserProperties = () => {
      const { user } = applicationContexts;
      if (user && !isEmptyObj(user)) {
        return {
          userName: user?.name,
          userEmail: user?.email,
          userNumber: user?.mobile,
          userId: user?.id,
        };
      }
    };

    const applicationContexts = useSelector((state) => state);

    return (
      <WrappedComponent {...props} trackEvents={getTrackingEventActions()} />
    );
  };
};

export default WithTracker;
