import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "../../theme/theme";
import routes from "../../routes";
import { getUser } from "../../redux/actions/userActions";
import AppContainer from "./appContainer";
import RightBanner from "./rightBanner";
import TrackingScripts from "../TrackingScripts";
import {
  locationState,
  getUrlPrefix,
  getUTMParamsFromUrl,
  isEmptyObj,
  checkIfObjectsSame,
} from "../../Utils";
import { UTM_PARAMS_KEY, ROUTES } from "../../constants";
import ErrorBoundary from "../ErrorBoundary";
import Loader from "../Common/Loader";
import NotificationBar from "../Common/NotificationBar";
import { PUBLIC_URL } from "../../config";

function App() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { user: currentUser, loading, notification } = state;
  const publicPathName = window.location.pathname;
  const publicUrl = publicPathName.split("/")?.[1];

  useEffect(() => {
    dispatch(getUser());
  }, []);

  useEffect(() => {
    const utmParams = getUTMParamsFromUrl();
    if (!isEmptyObj(utmParams)) {
      const storedUTMParamsObj = localStorage.getItem(UTM_PARAMS_KEY);
      const parsedUTMParamsObj = storedUTMParamsObj
        ? JSON.parse(storedUTMParamsObj)
        : {};
      const { utmParams: storedUTMParams } = parsedUTMParamsObj;

      if (!checkIfObjectsSame(storedUTMParams, utmParams)) {
        const utmParamsForStorage = {
          utmParams,
          setDate: new Date(),
        };
        localStorage.setItem(
          UTM_PARAMS_KEY,
          JSON.stringify(utmParamsForStorage)
        );
      }
    }
  }, []);

  const checkAuth = (route, props, prevPathVal, currentPathLocationState) => {
    if (loading) {
      return <Loader isLoading={loading} />;
    }

    if (!isEmptyObj(currentUser)) {
      return <route.component {...props} prevPath={prevPathVal} />;
    } else {
      return (
        <Redirect
          to={{
            pathname: ROUTES.login,
            state: {
              prevPath: prevPathVal,
              redirectPath: currentPathLocationState?.pathname,
            },
          }}
        />
      );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <AppContainer>
        <ErrorBoundary>
          <TrackingScripts />
          <BrowserRouter
            basename={
              PUBLIC_URL === "/online-classes" ? `/${publicUrl}` : PUBLIC_URL
            }
          >
            {/* <ForceReloadOnRouteChange /> */}
            <Switch>
              {routes.map((route) => {
                const { isPrivate, name } = route;
                const routeURL = route.isPrefix
                  ? `${getUrlPrefix([], true)}/${route.url}`
                  : route.url;
                return (
                  <Route
                    exact
                    path={routeURL}
                    component={(props) => {
                      const { prevUrl } = locationState(props?.location);
                      const { pathname, state } = props?.location;

                      return isPrivate ? (
                        checkAuth(route, props, prevUrl, { pathname, state })
                      ) : (
                        <route.component {...props} />
                      );
                    }}
                    key={`${name}`}
                  />
                );
              })}
            </Switch>
          </BrowserRouter>
        </ErrorBoundary>
        <Loader isLoading={loading} />
        <NotificationBar notification={notification} />
      </AppContainer>
      <RightBanner />
    </ThemeProvider>
  );
}

export default App;
