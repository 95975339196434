import { makeStyles } from "@material-ui/core/styles";
import bgImage from "../../images/new-rightBanner-agk.png";

const drawerWidth = 240;

const style = makeStyles((theme) => ({
  appContainer: {
   maxWidth: 480,
    height: "100%",

    [theme.breakpoints.down("xs", "sm")]: {
      width: "100%",
    },
  },
  rightBannerContainer: {
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 480,
  },
  rightBanner: {
    height: "100%",
    width: "100%",

    "@media (max-width:550px)": {
      display: "none",
    },
    "@media (min-width:550px) and (max-width:1150px)": {
      display: "none",
    },
    "@media (min-width:1150px)": {
      display: "block",
    },
  },

  rightBannerBg: {
    // background: `url(${bgImage}) no-repeat left top`,
    height: "100%",
    width: "100%",

    "@media (max-width:550px)": {
      display: "none",
    },
    "@media (min-width:550px) and (max-width:1150px)": {
      background: `url(${bgImage}) no-repeat left top`,
      height: "100vh"
    },
    "@media (min-width:1150px)": {
      display: "none",
    },
  },

  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default style;
