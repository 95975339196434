import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  notificationWrapper: {
    width: "100%",
    maxWidth: 480,
    padding: "0 8px",
    left: 0,
    "& > div": {
      width: "100%",
    },
  },
  loadingWrapper: {
    position: "absolute",
    display: "flex",
    width: "100%",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    left: 0,
    zIndex: 1000,
    background: " #a9a9a961",
    margin: 0,
    padding: 0,
    overflow: "unset",
    maxWidth: 480,
  },
  notificationRoot: {
    marginLeft: -25,
  },
}));

export default useStyles;
