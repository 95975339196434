import React from "react";
import useStyles from "./styles";

function AppContainer(props) {
  const classes = useStyles();
  const { children } = props;

  return <div className={classes.appContainer}>{children}</div>;
}

export default AppContainer;
