import React, { useEffect, useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { ALERT_MSG_TYPE } from "../../constants";
import useStyles from "./styles";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const NotificationBar = ({ notification }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(notification?.show);

  useEffect(() => {
    setOpen(notification?.show);
  }, [notification]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        className={classes.notificationWrapper}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        classes={{ root: classes.notificationRoot }}
      >
        <Alert
          onClose={handleClose}
          severity={notification?.msgType || ALERT_MSG_TYPE.SUCCESS}
        >
          {notification?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default NotificationBar;
