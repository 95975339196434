import { withStyles } from "@material-ui/core/styles";

const styles = withStyles((theme) => ({
  errorWrapper: {
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    textAlign: "center",
  },
  reloadBtn: {
    background: theme.palette.success.dark,
    cursor: "pointer",
    color: theme.palette.whiteColor,
    height: 30,
    maxWidth: "300px",
    width: "50%",
  },
}));

export default styles;
