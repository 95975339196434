import { LOGIN, USER, UPDATE_USER } from '../constants';

const initialUserState = {};


function userReducer(state = initialUserState, action) {
  switch (action?.type) {
    case LOGIN:
      return { ...state, ...action?.payload };
    case USER:
      return { ...state, ...action?.payload };
    case UPDATE_USER:
      return { ...state, ...action?.payload };
    default:
      return state;
  }
};

export default userReducer;
