import React from "react";
import { Helmet } from "react-helmet";

function SeoManager({ title, metaDetails, canonicalUrl, logoImage, ogTitle }) {
  const ogTagsList = ["description"];
  const currentPageUrl = window.location.href;

  return (
    <Helmet defaultTitle="Khelomore Sports Coaching">
      <meta charSet="utf-8" />
      <title>{title}</title>
      <link rel="canonical" href={window.location.href} />

      {metaDetails?.map((metaDetail) => (
        <meta
          key={metaDetail.key}
          name={metaDetail.key}
          content={metaDetail.value}
        />
      ))}
      <meta property="og:title" content={ogTitle || title} />
      {logoImage && <meta property="og:image" content={logoImage} />}
      <meta property="og:url" content={currentPageUrl} />
    </Helmet>
  );
}

export default SeoManager;
