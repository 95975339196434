export const ROUTES = {
  login: "/login",
  verifyOtp: "/verify-otp",
  personalDetails: "/personal-details",
  myProfile: "/my-profile",
  root: "/",
  wellnessUI: "/one-on-one-personal-training",
  courseDetails: [
    "/courses/:courseName/:courseCode/",
    "/courses/:courseName/:courseCode/preview",
  ],
  slotSelection: [
    "/courses/:courseName/:courseId/select-slots",
    "/:category/courses/:courseName/:courseId/select-slots",
  ],
  bookingReview: "/checkout/review/:cartId",
  confirmationDetails: "/booking/:bookingId/confirmation-details",
  categoryPage: "/categories/",
  bookings: "/my-courses",
  credits: "/credits",
  credits_view_all: "/credits/view-all",
  batches: [
    "/courses/:courseName/:courseCode/batches",
    "/courses/:courseName/:courseCode/preview/batches",
  ],
  slots: [
    "/courses/:courseName/:courseCode/:batchId/select-slot",
    "/courses/:courseName/:courseCode/preview/:batchid/select-slot",
  ],
  onBehalfReview: "/onbehalf-review/:id",
  onBehalfConfirmation: "/onbehalf-confirmation/:id",
};

export const SCREEN_NAME = {
  phoneLogin: "phone-login",
  verifyOtp: "verify-otp",
  personalDetails: "personal-details",
  myProfile: "my-profile",
  courseDetails: "course-details",
  bookingReview: "booking-review",
  slotSelection: "slot-selection",
  confirmationDetails: "confirmation-details",
  DASHBOARD: "dashboard",
  categoryPage: "category-page",
  bookings: "my-courses",
  batchPage: "batch-page",
  SlotPage: "slot-page",
  WellnessAndMore: "one-on-one-personal-training",
};

export const RIGHT_HAMBURGER_LIST = Object.freeze({
  COACHES_AND_ACADEMIES: "Coaches & Academies",
  EVENTS: "Events",
  TOURNAMENTS: "Tournaments",
  YOUR_COURSES: "Your Bookings",
  WELLNESS_BOOKINGS: "1 on 1 Training Session",
  KHELOMORE_CREDITS: "Khelomore Credits",
  PNP: "Pay N Play",
  BLOG: "Blogs and Articles",
  TERMS_CONDITIONS: "Terms and Conditions",
  PRIVACY_POLICY: "Privacy Policy",
});

export const KHELOMORE = "KheloMore";
export const DEFAULT_PROFILE_GENDER = "m";

export const UTM_PARAMS_KEY = "km-utm";
export const UTM_DAYS_TTL = 1440;
export const MOBILE_REGEX = /^[6-9][0-9]{9}$/;
export const EMAIL_REGEX = /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/;
export const PREV_URL = "prevUrl";
export const REDIRECT_URL_AFTER_LOGIN = "redirectUrl";

// Right Hamburger Links
export const KM_PRIVACY_LINK = "https://www.khelomore.com/privacy-policy";
export const KM_FAQ_LINK = "https://pages.khelomore.com/public/faq.html";
export const KM_ACADEMIES_LINK =
  "https://www.khelomore.com/sports-coaches-and-academies";
export const KM_EVENTS_LINK =
  "https://www.khelomore.com/landing/lockdownmeinkhelomore";
export const KM_TOURNAMENTS_LINK = "https://www.khelomore.com/train-at-home";
export const KM_BLOG_LINK = "https://blog.khelomore.com/";
export const PNP_LINK = "https://www.khelomore.com/sports-venues";
export const KM_TERMS_CONDITIONS = "https://www.khelomore.com/terms-conditions";

export const GOOGLE_MAP_URL =
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyDGJ_0Y5tCkbMRf4daZoQTOtz-wrc5eSRM&v=3.exp&libraries=geometry,drawing,places";

export const LOGIN_FLOW = Object.freeze({
  BOOKING_PROCESS: "Booking Process",
  RIGHT_HAMBURGER: "RightHamburger",
  DIRECT_LOGIN: "DirectLogin",
});

export const ERROR_MESSAGES = Object.freeze({
  NAME_ERR_MSG: "User Name is required",
  EMAIL_ERR_MSG: "Email address is required",
  INVALID_EMAIL_MSG: "Email address is invalid",
});

export const LOGIN_PAGE = Object.freeze({
  LOGIN: "LOGIN",
  LOGIN_TITLE: "Login",
  GUEST_USER: "Guest User",
  LOGIN_SIGNUP_TEXT: "Login or sign up to continue.",
  WELCOME_TO: "WELCOME TO",
  AGK: "KHELOMORE",
  SUBMIT: "Submit",
  MOBILE_NUMBER: "Mobile Number",
  PERSONAL_DETAILS_TITLE: "Personal Details",
  VERIFICATION_CODE: "Verify with OTP",
  SEND_OTP_INFO: "Please enter the OTP sent to",
  DIDNT_RECEIVE: "Didn't Receive OTP?",
  MOBILE_NUMBER_DIGIT_MSG: "Mobile Number should be only 10 digits",
  VALID_MOBILE_NUMBER_MSG: "Please enter a valid mobile number",
  RESEND_OTP: "Resend OTP",
  RESEND_IN: "Resend in",
  VERIFY_AND_PROCESS: "Verify",
  ENTER_REFERRAL_CODE_INFO: "Enter your referral code (optional)",
  EMAIL_ID: "Email ID",
  NAME: "Name",
  CONFIRM_BTN: "CONFIRM",
  EDIT: "EDIT",
  MY_PROFILE: "My Profile",
  AGE: "Age",
  CITY: "City",
  REQUIRED_NAME_MSG: "Name is required",
  REQUIRED_CITY_MSG: "City is required",
  VALID_EMAIL_MSG: "Please enter a valid Email address",
  REQUIRED_EMAIL_MSG: "Email address is required",
  REQUIRED_AGE_MSG: "Age is required",
  REQUIRED_ADDRESS_MSG: "Address is required",
  REQUIRED_LOCALITY_MSG: "Locality is required",
  REQUIRED_CITY_MSG: "CITY is required",
  MY_COURSES: "My Courses",
  PINCODE: "Pincode",
  ADDRESS: "Address",
  HOUSE_NO: "House No, Building",
  LOCALITY_TOWN: "Locality/Town",
  STREET_AREA: "Street, Area",
  CITY_DISTRICT: "City/District",
  DOB: "DOB",
  PINCODE_DIGIT_MSG: "Pincode should be only 6 digits",
});

export const ICON = {
  DELETE_ICON: "deleteIcon",
  NO_COURSE_SVG: "noCourseSvg",
  DIRECTION_ICON: "directionIcon",
  OLD_LOCATION_ICON: "oldLocationIcon",
  NO_COURSE_ICON: "noCourseIcon",
  MAP_PIN_ICON: "mapPinIcon",
  CALL_ICON: "callIcon",
  DONE_ICON: "doneIcon",
  INFO_ICON: "infoIcon",
  NOTIFICATION_POP_UP: "notificationPopUp",
  BACK_ICON: "backIcon",
  FORWARD_ARROW_ICON: "forwardArrowIcon",
  CLOSE_ICON: "closeIcon",
  CANCEL_ICON: "cancelIcon",
  ARROW_DROPUP_ICON: "arrowDropUpIcon",
  ARROW_DROPDOWN_ICON: "arrowDropDownIcon",
  RIGHT_ARROW_ICON: "rightArrowIcon",
  EXPAND_ICON: "expandIcon",
  DOWN_ARROW_ICON: "downArrowIcon",
  PROFILE_ICON: "profileIcon",
  CALENDER_ICON: "calenderIcon",
  CLEAR_ICON: "clearIcon",
  ADD_CIRCLE_ICON: "addCircleIcon",
  BACK_ARROW: "backArrow",
  SHARE_ICON: "shareIcon",
  RIGHT_MARK: "rightMark",
  FORWORD_ICON: "forwordIcon",
  CHILDICON: "childIcon",
  NETWORK_ICON: "networkIcon",
  PEOPLE_ICON: "students-icon",
  LOW_LEVEL: "beginner",
  HEART_ICON: "session",
  CERTIFICATE_ICON: "certificate-icon",
  BASIC_NETWOK: "beginner",
  INTERMIDIATE_NETWORK: "intermediate",
  ADVANCE_NETWORK: "advance",
  COPY_ICON: "copyIcon",
  STAR_ICON: "starIcon",
  QUESTION_ANSWER_ICON: "questionAnswerIcon",
  COURSE_ICON: "courseCurriculamIcon",
  BATCHES_ICON: "batchDetalIcon",
  VIDEO_ICON: "video-icon",
  DOWNLOAD_ICON: "download-icon",
  S_PEOPLE_ICON: "people-icon",
  BORDER_STAR_ICON: "star-icon",
  VIDEO_CAM_ICON: "video-icon",
  VIDEO_PLAYER_ICON: "video-player-icon",
  WARNING_ICON: "warningIcon",
  FILTER_ICON: "filterIcon",
  DATE_RANGE: "dateRange",
  BOOKING_ICON: "bookingIcon",
  SEARCH_ICON: "searchIcon",
  DISCOUNT_ICON: "DiscountIcon",
  WALLET_ICON: "WalletIcon",
  ARROW_FARWORD: "farword",
  RADIO_BUTTON_UNCHECKED: "unchecked",
  RADIO_BUTTON_CHECKED: "checked",
  KEYBOARD_ARROW_RIGHT_ICON: "arrowRightIcon",
  OFFLINE_BOLT_ICON: "offlineBoltIcon",
  POOL_SVG: "poolSvg",
  TV_SVG: "tvSvg",
  PEOPLE_SVG: "popleSvg",
  CHILDCARE_ICON: "childCareIcon",
  BATCH_NAME_ICON: "batchNameIcon",
  BATCH_DURATION_ICON: "batchDurationIcon",
  BATCH_SIZE_ICON: "batchSizeIcon",
  COIN_ICON: "coinIcon",
  NOTE_ICON: "noteIcon",
  PLAY_ICON: "playIcon",
  LIKE_ICON: "likeIcon",
  THUNDER_ICON: "thunderIcon",
  FOOTBALL_ICON: "footballIcon",
  SWIM_POOL_ICON: "swimpollIcon",
  HOME_ICON: "homeIcon",
  SWIM_ICON: "swimIcon",
  CRICKET_BALL_ICON: "cricketBallIcon",
  BASKET_BALL_ICON: "basketBallIcon",
  BADMINTON_ICON: "badmintonIcon",
  NEW_INFO_ICON: "newInfoIcon",
  PERCENTAGE_ICON: "percentageIcon",
  PARENTARAL_ICON: "parenteralIcon",
  GROUP_ICON: "groupIcon",
  WHISTLE_ICON: "whistleIcon",
  NEW_CALENDAR_ICON: "newCalendarIcon",
  POPULAR_TAG_ICON: "popularTagIcon",
  ERROR_INFO_ICON: "ErrorInfoIcon",
  LEFT_ARROW_ICON: "LeftArrowIcon",
  FIRE_ICON: "fireIcon",
  VECTOR: "vector",
  USERS: "users",
  GROUP_ICON: "groups",
  FIRE_ICON: "fireIcon",
  HOUR_GLASS_ICON: "hourGlassIcon",
  COACHING_BOOKING_ICON: "coachingEmptyBookingIcon",
  BACK_ARROW_ICON: "backArrowIcon",
  ARROW_FORWARD_ICON: "arrowForwardIosIcon",
  DOTS_ICON: "dotsIcon",
  OFFER_ICON: "offerIcon",
  TSHIRT_ICON: "tshirtIcon",
  TOWEL_ICON: "towelIcon",
  GOGGLES_ICON: "gogglesIcon",
  BOTTLE_ICON: "bottleIcon",
  PLAY_ICON: "playIcon",
  CLOSEDRAWER_ICON: "closedrawerIcon",
  MAP_PIN_ICON: "mapPinIcon",
  EDIT_ICON: "editIcon",
  RED_PLAY_ICON: "redPlayIcon",
  DEFAULT_SPORT_ICON: "defaultSportIcon",
  GREEN_STAR_ICON: "greenStarIcon",
  WELNESS_GROUP_ICON: "wellnessGroupIcon",
};

export const ALERT_MSG_TYPE = {
  SUCCESS: "success",
  ERROR: "error",
};

export const FAQ_QUESTIONS = "Frequently Asked Questions";

export const YOUR_INSTRUCTOR = "About your instructor";

export const SHOW_DATA = 3;

export const COURSE_INCLUDES = "Class Inclusions";

export const READ_MORE = "READ MORE";
export const READ_LESS = "READ LESS";
export const WHAT_TO_DO = "Coaching Details";
export const FREE = "FREE";
export const TRIAL_PRICE = "Trial Price";
export const BOOK_NOW = "Book Now";
export const RUPEES = "\u20B9";
export const TOTAL_PRICE = "Total Price";
export const PRICE = "Price";
export const SESSION = "Sessions";
export const VIEW_MORE = "VIEW MORE";
export const BATCHES = "Training Batches";
export const LOAD_MORE = "LOAD MORE";
export const MORE_COURSES = "More Courses by";
export const SEE_ALL = "SEE ALL";
export const FIRST_REVIEW = "BE THE FIRST TO REVIEW";
export const SUBMIT = "SUBMIT";
export const YOUR_EXP = "How was your experience";
export const REVIEWS = "Reviews";
export const ACTIVE = "active";
export const COURSE_CURRICULUM = "Coaching Preview";
export const BOOK_TRIAL = "Book Trial Session";
export const DONT_STOP_LEARNING =
  "Don't let that stop you from learning. Go back & explore other exciting courses.";
export const NO_COURSES = "No courses available!";
export const ARRAY = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
export const DAYS = "Days";
export const PRO_RATA = "PRO_RATA";
export const OFFLINE_TYPE = "Offline";
export const AT_HOME_TYPE = "AT HOME";

// Slot Selection Page
export const STATUS_AVAILABLE = "AVAILABLE";
export const SLOT_SELECTION_TITLE = "Slot Selection";
export const CONTINUE = "CONTINUE";
export const CALENDAR_DAYS = 10;
export const DATE_FNS_FORMAT = "yyyy-MM-dd";
export const DAY_FORMAT = "DD";
export const MONTH_FORMAT = "MMM";
export const CURRENCY_SYMBOL = "₹";
export const DATE_FORMAT_DO = "Do MMM";
export const SLOT_LIST_DATE_FORMAT = "d MMM, yyyy";
export const BOOKING_DATE_FORMAT_2 = "d MMM yyyy";
export const SLOT_CARD_TIME_FORMAT = "h:mm aa";
export const PREVIEW = "preview";
export const YEAR = "years";
export const QUERY_NUMBER = "For any queries, please call at ";
export const JOINING_TIME =
  "The joining link will be shared 2 hours before the start time.";
export const SESSION_JOINING_DETAILS = "HOW TO JOIN";
export const TRAINING_ADDRESS = "Training address";
export const ACADEMY_ADDRESS = "Academy Address";

export const JOINING_LINK = "Joining Link";
export const COPY = "COPY";
export const PASSCODE = "Joining Passcode";
export const JOIN_SESSION = "JOIN SESSION";
export const NO_SLOTS_MSG = "No slots available!";
export const NO_SLOTS_MSG_INFO =
  "Change your selection criteria and try again.";
export const SLOT_TAP = "slotTap";
export const CHOOSE_DATE_TIME = "Choose Date & Time";

// Booking Review Page
export const ADD_PARTICIPANT = "Participant Details";
export const CART_ERROR_MSG = "Booking cart item's error";
export const MINUTES = "minutes";
export const BOOKING_REVIEW_TITLE = "Review Booking";
export const GO_BACK_TEXT = "GO BACK";
export const DISCARD_CART_MSG_TITLE = "This will clear your cart!";
export const DISCARD_CART_CONFIRM_MSG =
  "Going back will clear your cart.\n Are you sure you want to go back?";
export const DISCARD_SLOT_CONFIRM_MSG =
  "Going back will clear your current slot slection. Are you sure you want to go back?";

export const VIEW_LISTING_MSG = "View More Listings";

export const INTRACTION_TYPES = {
  FAQ_EXPANDED: "faq accordian expaned",
  CROUSEL_CLICKED: "Clicking on Image-Video",
  BATCHES_VIEW_MORE: "Batches View More",
  CURRICULUM_VIEW_MORE: "Curriculum View More",
  WHAT_YOU_WILLDO_READ_MORE: "What you'll do - read more",
  COURSE_INCLUDES_READ_MORE: "course includes - read more",
  INSTRUCTOR_READ_MORE: "instructor - read more",
  SEE_ALL_COURSE_CLICKED: "see all courses clicked",
};

export const EVENT_TYPE = {
  KEY_DOWN: "keydown",
  TAB: "Tab",
  SHIFT: "Shift",
  SLOT_SELECTION_LANDED: "SlotSelectionLanded",
  SLOT_SELECTION_INTERACTED: "SlotSelectionInteracted",
  CANLENDER_CLICKED: "calenderClicked",
  DATE_CHANGED: "dateChanged",
  SLOT_ADDED: "slotAdded",
  SLOT_REMOVED: "slotRemoved",
  COURSE_PAGE_BOOK_NOW_CLICKED: "CoursePageBookNowClicked",
  COURSE_PAGE_LANDED: "CoursePageLanded",
  COURSE_PAGE_INTRACTED: "CoursePageInteracted",
  SUBMITTED_PARTICIPANT_DETAILS: "SubmittedParticipantDetails",
  BOOKING_REVIEW_LANDED: "AgkBookingReviewPageLanded",
  BOOKING_REVIEW_INTERACTED: "BookingReviewInteracted",
  PAY_NOW_CLICKED: "AgkPayNowClicked",
  USER_SIGN_IN: "UserSignIn",
  MOBILE_NUMBER_CONTINUE: "MobileNumberContinue",
  OTP_SUBMITTED: "OtpSubmitted",
  RESEND_OTP_CLICKED: "ResendOtpClicked",
  PERSONAL_DETAILS_SUBMMITED: "PersonalDetailsSubmitted",
  BOOKING_CONFIRMATION_LANDED: "AgkBookingConfirmation",
  BOOKING_CONFIRMATION_INTERACTED: "BookingConfirmationInteracted",
  CONTINUE_CLICKED: "ContinueClicked",
  CATEGORY_PAGE_INTRACTED: "CategoryPageLanded",
  CATEGORY_PAGE_BANNER_CLICKED: "CategoryPageBannerClicked",
  CATEGORY_COURSE_CARD_CLICKED: "CategoryCourseCardClicked",
  CATEGORY_PAGE_FILTER_INTERACTED: "CategoryPageFilterInteracted",
  BOOKING_CARD_CLICKED: "BookingCardClicked",
  SESSION_JOINED_MYBOOKING: "SessionJoinedMyBooking",
  JOIN_SESSION_CLICKED: "JoinSessionClicked",
  BATCH_PAGE_LANDED: "BatchPageLanded",
  BATCH_PAGE_INTERACTED: "BatchPageInteracted",
  COURSEPAGE_BATCHCARD_CLICKED: "CoursePageBatchCardClicked",
};
export const FB_EVENT_TYPE = Object.freeze({
  INITIAL_CHECKOUT: "InitiateCheckout",
  COMPLETE_REGISTRATION: "CompleteRegistration",
  ADD_TO_CART: "AddToCart",
  PURCHASE: "Purchase",
});
export const CONFIRMATION_DETAIL_CONST = {
  STATUS: "Status",
  SESSION_JOINING_DETAIL: "How to join the session?",
  DETAIL_ON_JOINING: "Click here to know the session joining details",
  DISCOUNT: "Discount",
  TRIAL: "(Trial)",
  TOTAL: "Total",
  FREE: "Free",
  SESSION_PRICE: "Session price",
  BILL_DETAILS: "Bill Details",
  ID: "ID:",
  CONFIRMATION: "CONFIRMATION",
  TRIAL_SESSION: "(Trial Session)",
  SESSION_DETAILS: "Batch Details",
  BATCH_DETAILS: "Batch Details",
  YEARS: "Yrs",
  PAYMENT_NOT_COMPLETED_MSG:
    "Unfortunately your payment request could not be completed.Please try again.",
  PARTICIPANT_DETAILS: "Participant Details",
  NAME: "Name",
  DOB: "DOB",
  ADDRESS: "Address",
  PARTICIPANT: "Participant:",
  CONVENIENCE_FEES: "Convenience Fees",
  ON_BASE_PRICE_TEXT: "ON BASE PRICE",
};
export const SIZE = "size";
export const DATE_FORMAT = "d MMM yyyy";

export const timeConvert = (time) => {
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    time = time.slice(1);
    time[5] = +time[0] < 12 ? "AM" : "PM";
    time[0] = +time[0] % 12 || 12;
  }
  const timeArr = time.join("").split(":");
  return timeArr[0] + ":" + timeArr[1] + " " + timeArr[2].slice(2);
};

export const DATE_FORMATS = Object.freeze({
  BOOKING_CONFIRMATION_PAGE_DATE_FORMAT: "eee dd MMM ''yy",
  BATCH_DETAILS_DATE_FORMAT: "d MMM yyyy",
  COURSE_PAGE_DATE_FORMAT: "do MMMM yyyy",
  COURSE_PAGE_BOTTOM_DATE: "do MMM yy",
  SESSION_DETAILS_DATE_FORMAT: "d MMM ''yy",
  BATCH_CARD_DATE_FORMAT: "dd MMM, yy",
});

// Filter page
export const FILTER_TITLE = "Filter";
export const MEETING_LINK_COPIED_MESSAGE = "Meeting link copied to clipboard!";

export const KEY_STORE_INPUTS = "currentUserInput";

export const SPORTS_FILTER = "sports";
export const AGE_FILTER = "ageFilter";
export const DIFICULTY_FILTER = "difficulty";
export const ACADEMY_FILTER = "academy";
export const CATEGORY_FILTER = "category";
export const CITY_FILTER = "city";
export const LOCALITY_FILTER = "locality";
export const PRICE_FILTER = "price";
export const BATCH_TYPE_FILTER = "course type";

export const SELECT_OPTIONS_HEADING = {
  [SPORTS_FILTER]: "sports",
  [AGE_FILTER]: "age",
  [DIFICULTY_FILTER]: "difficulty",
  [ACADEMY_FILTER]: "academy",
  [CATEGORY_FILTER]: "category",
  [CITY_FILTER]: "city",
  [LOCALITY_FILTER]: "locality",
};

export const AVAILABLE_COURSES = "Group Batches";
export const WELLNESS_AND_MORE = "Personal Training";
export const KEY_STORE_SLOTLIST = "selectedSlots";
export const KEY_STORE_BATCH = "selectedBatch";
export const KEY_STORE_PARTICIPANT = "participantInput";
export const KEY_STORE_LIST_OF_BOOKINGS = "LIST_OF_BOOKINGS";
export const TRACK = "track";
export const INR = "INR";
export const BOOKING_EMPTY_MSG_UPCOMING =
  "Aren’t you planning to learn something new? Select & book a course NOW! ";

export const BOOKING_EMPTY_MSG_PREV =
  "It’s been a while you’ve learnt something new, select & book a course now!";
export const WRITE_REVIEW = "WRITE A REVIEW";
export const OPTION_LIST = [
  { label: "Poor", value: 1 },
  { label: "Good", value: 2 },
  { label: "Best", value: 3 },
  { label: "Excellent", value: 4 },
];
export const REVIEW_QUESTIONS = [
  {
    id: 1,
    name: "How easy it was to book and complete the course with Khelomore?",
  },
  {
    id: 2,
    name: "How was your experience with the trainer?",
  },
];
export const GEO_LOCATION_ERROR = "GEO_LOCATION_ERROR";
export const TRIAL_COURSE = "TRIAL_COURSE";
export const SUCCESS_CODE = 200;

export const MOBILE_SAFARI = "Mobile Safari";

export const BANNER_CONST = {
  MOBILE_WIDTH: 430,
  SCROLL_LEFT: 140,
  TOP_BANNER_MOBO: 80,
  TOP_BANNER_DESK: 470,
  MIDDLE_BANNER_MOBO: 40,
  MIDDLE_BANNER_DESK: 510,
};
export const arrayOfFaqs = [
  {
    id: 1,
    title: "What is KheloMore Credits?",
    description:
      "KheloMore Credits is a virtual store that saves the balance money that you will receive through referrals, cashback or other promotional offers. Please note that 1 Point = 1 INR.",
  },
  {
    id: 2,
    title: "How to earn KheloMore Credits?",
    description:
      "You can earn KheloMore Credits by referrals, using cashback coupons or any promotional credits given by KheloMore.",
  },
  {
    id: 3,
    title: "How to redeem KheloMore Credits?",
    description:
      "You can redeem KheloMore Credits every time you make a coaching booking on KheloMore. You'll get upto 25% discount on the total booking amount which varies batches to batches, some batches does not support Khelomore Credits*. Wherein the discounted amount will be equivalent to the KheloMore Credits you have. If you don't have enough KheloMore Credits, all of the remaining Credits will be used for the discount.",
  },
  {
    id: 4,
    title: "Do KheloMore Credits have an expiry date?",
    description:
      "Yes, all cashback will come with clearly indicated expiry dates. You also check expiry dates in recent transactions.",
  },
  {
    id: 5,
    title: "Can I transfer KheloMore Credits to my bank account?",
    description: "No. KheloMore Credits are non-transferable",
  },
  {
    id: 6,
    title: "Can I send KheloMore Credits to other friends?",
    description: "No. KheloMore Credits are non-transferable.",
  },
  {
    id: 7,
    title: "Can I add money to my KheloMore Credits store?",
    description:
      "No. As KheloMore Credits is not a wallet but a virtual store exclusively for you.",
  },
  {
    id: 8,
    title: "Where can I view my KheloMore Credits transactions?",
    description:
      "You can check all your history in ‘View Transactions’ on the KheloMore Credits page.",
  },
  {
    id: 9,
    title: "Where can I report issues? ",
    description: "You can get in touch with us via email at info@khelomore.com",
  },
];

export const BOOKING_DETAILS_DATE_FORMAT = "dd MMM yyyy | h:mm a";
export const CREDIT = "CREDIT";
export const APPLY_COUPON = "Apply Coupon";

export const KM_CREDIT_EVENTS = {
  KM_CREDITS_PAGE_LANDED: "KMCreditsPageLanded",
  KM_CREDITS_PAGE_INTERACTED: "KMCreditsPageInteracted",
  TRANSACTIONS_PAGE_LANDED: "TransactionsPageLanded",
};

export const KM_CREDIT_CONST = {
  FAQ: "FAQ",
  RECENT_TRANSACTIONS: "RECENT TRANSACTIONS",
  TRANSACTIONS: "Recent Transactions",
  VIEW_ALL: "View All",
  GIFT_ICON: "giftIcon",
  REWARD_BONUS: "Reward bonus will expire on",
  KHELO_MONEY: "KheloMoney",
  YOUR_BALANCE: "Your Balance",
  KHELO_MONEY_TEXT:
    "*KheloMore Credits can only be used for online class booking.",
};
export const RECMONDED_TEXT = "recommended";
export const BATCHES_TITLE = "Batches";
export const PLUS_CHARGES = "Plus Charges";
export const SESSIONS = "sessions";
export const ONE_SESSION = "session";
export const MONTHLY_PASS = "monthly Pass";
export const DURATION = "Duration:";
export const EXPIRY = "Expiry:";
export const WEEK_ARRAY = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
export const ENTER_COUPON_CODE_MSG = "Please enter coupon code";
export const APPLIED = "Applied";
export const APPLIED_COUPON_DISCOUNT_INFO = (amount) =>
  `You saved Rs.${amount} with this coupon`;
export const APPLIED_COUPON_CASHBACK_INFO = (amount) =>
  `You get Rs.${amount} cashback with this coupon`;
export const RECOMENDED = "recommended";
export const ONLINE_TEXT = "Online";
export const COUPON = "COUPON";
export const OFFLINE = "OFFLINE";
export const PARTICIPANT_DETAILS = "Participant Details";
export const CART_ITEM_REMOVE_ACTION = "remove";
export const KHELOMORE_CREDITS = "KheloMore Credits";
export const APPLY = "Apply";
export const BY_APPLYING = "by applying";
export const SAVE = "Save";
export const COIN_DETAILS_INFO_KEY = "COINS_DETAILS";
export const CLOSE = "CLOSE";
export const KM_CREDIT_APPLIED = "Khelomore Credits APPLIED";
export const KM_CREDIT_APPLIED_INFO_1 = "Savings with Khelomore Credits";
export const KM_CREDIT_APPLIED_INFO_2 = "on your entire purchase";
export const ADD_PARTICIPANT_DETAILS = "Add Participant Details";
export const KEY_KM_CREDIT_APPLIED = "kmCreditApplied";
export const CONFIRMATION_TEXT = "Confirmation";

export const getDayName = (day) => {
  switch (day.toLowerCase()) {
    case "mon":
      return "Monday";
    case "tue":
      return "Tuesday";
    case "wed":
      return "Wednesday";
    case "thu":
      return "Thursday";
    case "fri":
      return "Friday";
    case "sat":
      return "Saturday";
    case "sun":
      return "Sunday";
    default:
      return "";
  }
};

export const CONFIRMATION_PAGE_STRINGS = {
  COUPON: "Coupon",
  KM_CREDITS: "Khelomore Credits",
  INIT_TIME: "00:00",
  DISCRIPTION: "khelomore share link!",
  COPIED: "copied!",
  TITLE: "Khelomore",
  PARTICIPANT: "Participant:",
  START_DATE: "Start Date: ",
  END_DATE: "End Date: ",
  EXPIRY: "Expiry: ",
  MORE_INFO: "For more details ",
  LOGIN: "Login",
  JOINING_LINK_INFO:
    "To join the online session, click on 'Join Session' below or copy paste the joining link on your device browser.",
  SESSION_DETAILS_TEXT: "Sessions Details",
};

export const JOIN_NOW = "JOIN NOW";
export const JOINING_DETAILS = "Joining Detail";
export const VIEW_DETAILS = "VIEW DETAILS";
export const POSITION_MIDDLE = "middle";
export const POSITION_TOP = "top";

export const SINGLE_SESSION = "SINGLE_SESSION";
export const MONTHLY_PASSES = "MONTHLY_PASS";

export const EXPIRY_DATE = "Start Date:";
export const MONTHLY = "(Monthly Pass)";
export const ONLINE = "online";
export const SESSION_DETAIL = "SESSION DETAILS";

export const BOOKING_DATE_FORMAT_1 = "d MMM, yy";

export const CITY = "city";

export const SLOT_PAGE_EVENTS = {
  SLOT_SELECTION_PAGE_LANDED: "AgkSlotSelectionPageLanded",
  DAY_COMBINATION_INTERACTED: "DayCombinationInteracted",
  TIME_SLOT_SELECTED: "TimeSlotSelected",
};

export const UPTO_FIVE = "0-5";
export const UPTO_SIXTEEN = "16-100";

export const ADDRESS_INFO =
  "Our coach will reach the below-mentioned society: ";
export const ACADEMY_ADDRESS_INFO =
  "Please be there at the below-mentioned society.";

export const ABOUT_YOUR_ACADEMY = "About your Academy";
export const S_NAMES = ["ballet", "zumba", "dance"];

export const INVALID_DATE = "Invalid Date";

export const filterList = [
  "difficulty",
  "sports",
  "city",
  "category",
  "academy",
  "age",
  "locality",
];

export const getSportsIcon = (sportName) => {
  switch (sportName.toLowerCase()) {
    case "football":
      return ICON.FOOTBALL_ICON;
    case "swimming":
      return ICON.SWIM_ICON;
    case "badminton":
      return ICON.BADMINTON_ICON;
    case "cricket":
      return ICON.CRICKET_BALL_ICON;
    default:
      return ICON.DEFAULT_SPORT_ICON;
  }
};
