import React from "react";
import useStyles from "./styles";
import rightBanner from "../../images/new-rightBanner-agk.png";


function RightBanner(props) {
  const classes = useStyles();

  return <div className={classes.rightBannerContainer}>
         <img className={classes.rightBanner} src={rightBanner} alt="right-banner" />
    <div className={classes.rightBannerBg} />
  </div>;
}

export default RightBanner;
