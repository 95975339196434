import React from "react";
import WithTracker from "../WithTracker";
import withStyles from "./styles";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: "",
      showReloadButton: true,
    };
  }

  static getDerivedStateFromError(error) {
    const { message, name, showReloadButton } = error;
    return {
      hasError: true,
      errorMessage: message,
      showReloadButton: showReloadButton !== false,
    };
  }

  componentDidCatch(error, errorInfo) {
    const { message, stack } = error;
    const errorDetails = {
      message,
      stack,
      api: window?.location?.href,
    };
    // updateErrorMessage(errorDetails);
  }

  reloadScreen = () => {
    const { history } = this.props;
    if (history) {
      history.go(0);
    }
  };

  getErrorScreen = () => {
    const { classes } = this.props;
    return (
      <div className={classes.errorWrapper}>
        {this.state.errorMessage}

        {this.state.showReloadButton && (
          <button onClick={this.reloadScreen} className={classes.reloadBtn}>
            Reload
          </button>
        )}
      </div>
    );
  };

  render() {
    return this.state.hasError ? this.getErrorScreen() : this.props.children;
  }
}

export default withStyles(WithTracker(ErrorBoundary));
