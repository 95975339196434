import {
  getCleverTapScript,
  getFacebookSDKScript,
  //getSegmentTrackingKey,
} from "./trackingScripts";
import { getUADetails, getUtmParamsObject, isEmptyObj } from "../Utils";

export const analyticsTrackingScriptsGetters = [
  //getSegmentTrackingKey,
  getCleverTapScript,
  getFacebookSDKScript,
];

const generateCompleteTrackingObject = (eventProperties) => {
  const uaDetails = getUADetails();
  const utmParams = getUtmParamsObject();

  if (!isEmptyObj(utmParams))
    return { ...eventProperties, ...uaDetails, utmParams };
  else return { ...eventProperties, ...uaDetails };
};

const dispatchSegmentEvent = (trackingObject) => {
  //window?.analytics?.track(trackingObject.event, trackingObject.params);
  window?.clevertap?.event?.push(trackingObject.event, trackingObject.params);
  console.log(trackingObject.event , "-->" ,trackingObject.params);
};

const pushOnUserLoginCleverTap = (properties) => {
  const { mobileNumber, emailId, userId, userName } = properties;

  // window?.analytics?.identify(userId, {
  //   name: userName,
  //   email: emailId,
  //   phone: mobileNumber,
  // });

  window.clevertap.onUserLogin.push({
    Site: {
      Name: userName,
      Identity: userId,
      Email: emailId,
      Phone: mobileNumber,
      DOB: new Date("Thu Jan 01 1970"),
    },
  });
};

export const DispatchTrackingEvents = (eventDetails = {}) => {
  const { name, properties, options = {} } = eventDetails || {};
  const trackingObject = {
    event: name,
    params: generateCompleteTrackingObject(properties),
  };

  if (options.isUserLogin) {
    pushOnUserLoginCleverTap(properties);
  }
  dispatchSegmentEvent(trackingObject);
};

const commonEventActions = (applicationContexts, additionalProperties = {}) => {
  return {
    ...additionalProperties,
  };
};

const allEventActions = (applicationContexts, dispatchEvents) => {
  return {
    commonEventActions: (additionalProperties) =>
      dispatchEvents(
        commonEventActions(applicationContexts, additionalProperties)
      ),
  };
};

export const getAllEventTrackers = () => {
  return {
    events: allEventActions,
  };
};
