import { ApolloClient } from "apollo-client";
import { from } from "apollo-link";
import httplink from "./apolloLinks/httplink";
import { kmheaders } from "./apolloLinks/kmheaders";
import { errorlink } from "./apolloLinks/errorlink";
import { InMemoryCache } from "apollo-cache-inmemory";

const cache = new InMemoryCache();
const link = from([kmheaders, errorlink, httplink]);
const client = new ApolloClient({
  defaultOptions: {
    watchQuery: {
      errorPolicy: "all",
    },
    query: {
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
  link,
  cache,
  connectToDevTools: true,
  credentials: "include",
});

export default function getClient() {
  return client;
}
