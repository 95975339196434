import { combineReducers } from "redux";
import userReducer from './userReducer';
import { LOADER, SHOW_NOTIFICATION } from '../constants';

function loaderReducer(state = true, action) {
  if (action?.type === LOADER) return action?.payload;
  else return state;
};

function notificationReducer(state = { show: false }, action) {
  if (action?.type === SHOW_NOTIFICATION) return { ...state, ...action?.payload };
  else return state;
};

const rootReducer = combineReducers({
  user: userReducer,
  loading: loaderReducer,
  notification: notificationReducer,
});

export default rootReducer;
