import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import useStyles from './styles';


function Loader({ isLoading }) {
  const classes = useStyles();

  return isLoading ? (
    <div className={classes.loadingWrapper}>
      <CircularProgress color="primary" />
    </div>
  ) : null;
}

export default Loader;
