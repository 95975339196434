import { onError } from "apollo-link-error";
import updateErrorMessage from "../../components/ErrorBoundary/errorCurlCall";

export const errorlink = onError((error) => {
  const errordetails = {
    message: error?.graphQLErrors,
    api: error?.operation?.operationName,
    response_body: error?.response?.errors,
    parameters: error?.operation?.variables,
  };

  if (error?.response?.errors) {
    updateErrorMessage(errordetails);
  }
});
