import * as UaParserJs from "ua-parser-js";
import { APP_NAME, APP_VERSION } from "../config";
import { UTM_PARAMS_KEY, PREV_URL, UTM_DAYS_TTL } from "../constants";
import dateFnsParse from "date-fns/parse";
import format from "date-fns/esm/format";
import dateFnsFormat from "date-fns/format";

export function getCookie(name) {
  const cookieArr = document.cookie.split(";");
  for (const item of cookieArr) {
    const cookiePair = item.split("=");
    if (name === cookiePair[0].trim()) {
      return cookiePair[1];
    }
  }
  return null;
}
export const getRatingColor = (overallRating, numberOfRatings) => {
  return overallRating >= 3.5 && numberOfRatings > 0
    ? "textSuccess"
    : overallRating < 3.5 && overallRating >= 2.5 && numberOfRatings > 0
    ? "textInfo"
    : overallRating < 2.5 && numberOfRatings > 0
    ? "textError"
    : numberOfRatings === 0
    ? "textNew"
    : "textError";
};

export const parseNumber = (value) => {
  if (value && !isNaN(value)) return Number(value);
  return 0;
};

export const toKebabCase = (str) =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join("-");

const getDeviceDetailHeaderKey = (deviceDetailName) => {
  return "x-km-" + toKebabCase(deviceDetailName);
};
export const getDeviceDetailsHeaders = () => {
  const allDeviceDetails = { appVersion: APP_VERSION, appName: APP_NAME };
  return Object.keys(allDeviceDetails).reduce(
    (allDeviceDetailsHeaders, currentDeviceDetail) => {
      return {
        ...allDeviceDetailsHeaders,
        [getDeviceDetailHeaderKey(currentDeviceDetail)]:
          allDeviceDetails[currentDeviceDetail],
      };
    },
    {}
  );
};

export const getJWT = () => {
  return sessionStorage.getItem("__JWT__") || "";
};

export const getUADetails = () => {
  // const location = getUserLocation() || {};
  const location = {};
  const userAgentDetails = UaParserJs(navigator.userAgent);
  const navigatorDetails = window.navigator || {};
  return {
    appVersion: APP_VERSION,
    browserVersion: userAgentDetails?.browser?.version,
    browserName: userAgentDetails?.browser?.name,
    deviceID: userAgentDetails?.device?.model,
    osName: userAgentDetails?.os?.name,
    osVersion: userAgentDetails?.os?.version,
    platform: navigatorDetails?.platform,
    appName: APP_NAME,
    timestamp: new Date(),
    longitude: location?.lng,
    latitude: location?.lat,
    localitySelection: location?.address,
    citySelection: location?.city,
  };
};

export const getUserLoginFlow = (location) => {
  if (location?.state?.redirectPath?.includes("checkout/review")) {
    return "BookingProcess";
  } else if (location?.state?.rightHamburger) {
    return "RightHamburger";
  } else return "DirectLogin";
};

export const getUserEventProps = (user, location, additional = {}) => {
  return {
    mobileNumber: user?.mobile,
    emailId: user?.email,
    userId: user?.id,
    name: user?.name,
    source: location?.state?.prevPath || "/",
    loginFlow: getUserLoginFlow(location),
    ...additional,
  };
};

export const getUTMParamsFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);
  let paramObj = {};
  for (var value of urlParams.keys()) {
    paramObj[value] = urlParams.get(value);
  }

  if (!isEmptyObj(paramObj)) {
    return Object.keys(paramObj).reduce((acc, value) => {
      const isUTMQueryParam = value.toUpperCase().indexOf("UTM_") === 0;

      if (isUTMQueryParam) {
        return { ...acc, [value.toLowerCase()]: paramObj[value] };
      }

      return acc;
    }, {});
  }

  return {};
};

export const getUtmParameters = () => {
  const urlUTMParams = getUTMParamsFromUrl();
  const storedUTMParams = localStorage.getItem(UTM_PARAMS_KEY);

  if (!isEmptyObj(urlUTMParams)) {
    return JSON.stringify(urlUTMParams);
  } else if (storedUTMParams) {
    const parsedUTMObj = JSON.parse(storedUTMParams);
    const dateOfCreation = new Date(parsedUTMObj.setDate);
    const currentDate = new Date();
    dateOfCreation.setMinutes(dateOfCreation.getMinutes() + UTM_DAYS_TTL);

    if (currentDate.getTime() > dateOfCreation.getTime()) {
      localStorage.removeItem(UTM_PARAMS_KEY);
    } else {
      return JSON.stringify(parsedUTMObj.utmParams);
    }
  }

  return "{}";
};

export const getUtmParamsObject = () => {
  const kmUtm = getUtmParameters();
  return JSON.parse(kmUtm);
};

export function isEmptyObj(obj) {
  if (!obj) {
    return true;
  }
  if (Array.isArray(obj)) {
    return !obj.length;
  }
  return Object.getOwnPropertyNames(obj).length === 0;
}

export function locationState(location) {
  const path = location?.pathname;
  const store = window.localStorage;
  let url = "";
  let prevUrl = "";
  url = store.getItem("url");
  store.setItem(PREV_URL, url);
  store.setItem("url", path);
  url = store.getItem("url");
  prevUrl = store.getItem(PREV_URL);
  return { url, prevUrl };
}

export function transformUrl(str) {
  return (
    str &&
    `${str}`.trim().replace(/[-]/g, "").replace(/\s+/g, "-").toLowerCase()
  );
}

export function getUrlPrefix(pathParams, showLocality = false, customCity) {
  // const userLocation = getUserLocation();
  const userLocation = {};

  let str = "";

  if (userLocation.city) {
    str = `/${transformUrl(userLocation.city)}`;
  }

  if (customCity) {
    str = `/${transformUrl(customCity)}`;
  }

  if (pathParams && Array.isArray(pathParams)) {
    str = `${str}/${pathParams.map(transformUrl).join("/")}`;
  } else if (pathParams) {
    str = `${str}/${transformUrl(pathParams)}/`;
  }

  if (showLocality && userLocation.locality) {
    str = `${str}${transformUrl(userLocation.locality)}/`;
  }

  return str;
}

export const checkIfObjectsSame = (obj1, obj2) => {
  if (typeof obj1 === typeof obj2) {
    if (
      typeof obj1 === "object" &&
      typeof obj2 === "object" &&
      Array.isArray(obj1) === Array.isArray(obj2) &&
      Object.keys(obj1).length === Object.keys(obj2).length
    ) {
      for (var prop in obj1) {
        if (!checkIfObjectsSame(obj1[prop], obj2[prop])) return false;
      }

      return true;
    }
    return obj1 === obj2;
  }

  return false;
};

/**
 * The function will return true if website is opened in webview.
 * @returns {boolean} - True if website is opened in web view.
 */
export const isWebView = () => {
  return sessionStorage.getItem("__IsWebView__") || "";
};

export function getRouteHistoryDepth(currentPage, prepPath) {
  let pageRouteObj = routeHistory[currentPage];
  if (pageRouteObj && isNaN(pageRouteObj)) return pageRouteObj[prepPath];
  else if (pageRouteObj && !isNaN(pageRouteObj)) return pageRouteObj;
  else return -1;
}

export function extractErrorMessage(errors = {}) {
  const errorMsg = "Some error occurred!";

  if (!isEmptyObj(errors?.extensions)) {
    return errors?.extensions?.errorMessage;
  }

  return errorMsg;
}

export function getResponseFrom(response, type) {
  if (response === null) {
    return Promise.reject("Something went wrong!");
  }

  if (response?.loading) {
    return { loading: true, [type]: null, error: null };
  }

  if (response?.errors && response?.errors?.length > 0) {
    return Promise.reject(extractErrorMessage(response.errors[0]));
  }

  return { loading: false, [type]: response?.data?.[type], error: null };
}

export const backBtnClickHandler = (history) => {
  if (history?.location?.state?.prevPath) {
    let pathname = history?.location?.state?.prevPath;
    if (pathname?.includes("/online-classes")) {
      pathname = pathname.replace("/online-classes/", "/");
    } else if (pathname?.includes("/sports-coaching")) {
      pathname = pathname.replace("/sports-coaching/", "/");
    }
    history.push(pathname);
  } else history?.goBack();
};

export const routeRedirection = (history, state = {}) => {
  const { redirectPath, prevPath } = state;
  const pathname = redirectPath || prevPath || "/";
  history.replace({
    pathname,
    state: {
      prevPath,
      ...state,
    },
  });
};

// Add days for slot calenderbar
export function addDays(days, toDate) {
  let date = new Date();
  if (toDate) {
    date = new Date(toDate);
  }
  date.setDate(date.getDate() + days);
  return date;
}

export function slotSelectionDays(days, date) {
  let today = date;

  const formatStr = "eee dd MMM yyyy";
  let listOfDays = [];
  let i = 1;

  const getDayDetailsObj = (today) => {
    const details = format(today, formatStr);
    let dayDetails = details.split(" ");

    return {
      day: dayDetails?.[0],
      date: dayDetails?.[1],
      month: dayDetails?.[2],
      year: dayDetails?.[3],
      fullDate: new Date(today),
    };
  };

  listOfDays.push(getDayDetailsObj(today));

  while (i <= days) {
    const nextDay = new Date(today);
    nextDay.setDate(nextDay.getDate() + 1);
    today = nextDay;
    listOfDays.push(getDayDetailsObj(nextDay));
    i++;
  }
  return listOfDays;
}

export const formatCurrency = (value) => {
  if (value && !isNaN(value))
    return Number(parseFloat(value).toFixed(2)).toLocaleString("en-in");
  return value;
};

export const formatDate = (date, type) => {
  return format(date, type);
};

export const checkSlotPrice = (price, discountedPrice) => {
  return (
    !(Number(price) === 0 && Number(discountedPrice) === null) ||
    (Number(discountedPrice) === null && Number(price)) ||
    (Number(discountedPrice) === 0 && Number(price)) ||
    Number(price) > 0
  );
};

export const checkSlotDiscountedPrice = (price, discountedPrice) => {
  return Number(discountedPrice) > 0;
};

// export const getRatingColor = (overallRating, numberOfRatings) => {
//   return overallRating >= 4
//     ? "textSuccess"
//     : overallRating >= 3 && overallRating < 4
//     ? "textInfo"
//     : "textError";
// };

// Get Routes URL by params
const getUrlByName = (name) => {
  if (name?.includes(" "))
    return name?.toLocaleLowerCase()?.split(" ")?.join("-");
  else return name;
};

export const getCourseDetailsUrl = (courseId, courseName) =>
  `/courses/${getUrlByName(courseName)}/${courseId}`;
export const getCourseDetailsUrlByCategory = (category, courseId, courseName) =>
  `/${category}/courses/${getUrlByName(courseName)}/${courseId}`;
export const getSlotSelectionUrl = (courseId, courseName) =>
  `/courses/${getUrlByName(courseName)}/${courseId}/select-slots`;
export const getSlotSelectionUrlByCategory = (category, courseId, courseName) =>
  `/${category}/courses/${getUrlByName(courseName)}/${courseId}/select-slots`;
export const getBookingReviewUrl = (cartId) => `/checkout/review/${cartId}`;
export const getBookingConfirmationUrl = (bookingId) =>
  `/booking/${bookingId}/confirmation-details`;

export const getCourseBatchesUrl = (courseId, courseName) =>
  `/courses/${getUrlByName(courseName)}/${courseId}/batches`;

export const getSlotPageUrl = (courseId, courseName, batchId) =>
  `/courses/${getUrlByName(courseName)}/${courseId}/${batchId}/select-slot`;

export const getFormatedStringForAboutCourse = (aboutCourse) => {
  return aboutCourse.split("<br>")?.map((item) => {
    if (item?.includes("<item>")) {
      return item?.replaceAll("<item>", "")?.trim();
    }
    return item;
  });
};

export const getDateFormattedStringForDate = (date, time) => {
  let ddMmYyyy = date;
  if (date?.includes("/")) {
    const [dd, mm, yyyy] = date.split("/");
    ddMmYyyy = `${yyyy}-${mm}-${dd}`;
  }
  let hhMmSs = time;
  if (time.includes("AM") || time.includes("PM")) {
    [hhMmSs] = time.split(" ");
    let [hh, mm, ss] = hhMmSs.split(":");
    if (time.includes("PM")) {
      hh = `${+hh + 12}`;
    }
    if (!ss) {
      hhMmSs = `${hh}:${mm}:00`;
    } else {
      hhMmSs = `${hh}:${mm}:${ss}`;
    }
  }
  return `${ddMmYyyy}T${hhMmSs}`;
};

export function setAppliedFilters(appliedFilters) {
  localStorage.setItem("AGK_APPLIED_FILTERS", JSON.stringify(appliedFilters));
}

export function getAppliedFilters() {
  return localStorage.getItem("AGK_APPLIED_FILTERS")
    ? JSON.parse(localStorage.getItem("AGK_APPLIED_FILTERS"))
    : {};
}

export function getQueryParameter(query) {
  const queryObj = {};
  for (let [key, value] of Object.entries(query)) {
    if (key !== "") {
      queryObj[key] = value.split(",").map((item) => item.split("_").join(" "));
    }
  }
  return queryObj;
}
export const getCustomTime = (date) => {
  return dateFnsFormat(
    dateFnsParse(date.substring(0, 5), "HH:mm", new Date()),
    "h:mm a"
  );
};
export function debounce(func, time) {
  let timer;
  return function (...args) {
    let cb = () => func.apply(this, args);
    clearTimeout(timer);
    timer = setTimeout(cb, time);
  };
}

export function getUserLocationDisplayStr(location = null) {
  const userLocation = location || getUserLocation();

  let str = "";

  if (userLocation.city) {
    str = userLocation.city;
  }

  if (!userLocation.city && userLocation.state) {
    str = userLocation.state;
  }

  if (userLocation.locality) {
    str = `${userLocation.locality}, ${str}`;
  }

  return str;
}

export const getSearchUrl = (state) => {
  let searchParaMeter = "";
  let isfilter = false;
  for (const item in state) {
    if (state[item]?.length > 0) {
      isfilter = true;
      searchParaMeter += "&";
      searchParaMeter += item + "=";
      state[item]?.map((ele, idx) => {
        searchParaMeter += ele?.split(" ")?.join("_");
        if (idx !== state[item]?.length - 1) {
          searchParaMeter += ",";
        }
      });
    }
  }
  return isfilter
    ? "?" + searchParaMeter?.substring(1, searchParaMeter?.length)
    : "";
};

export function getAppliedCourseUrl() {
  return sessionStorage.getItem("AGK_APPLIED_COURSE_URL")
    ? sessionStorage.getItem("AGK_APPLIED_COURSE_URL")
    : "";
}

export function setAppliedCourseUrl(url = "/") {
  sessionStorage.setItem("AGK_APPLIED_COURSE_URL", url);
}

export function getAppliedCity() {
  return localStorage.getItem("AGK_APPLIED_CITY_NAME");
}

export function setAppliedCity(city) {
  localStorage.setItem("AGK_APPLIED_CITY_NAME", city);
}
