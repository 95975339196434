import getClient from '../../graphql/apolloClient';
import {
  LOGIN,
  UPDATE_USER,
  LOGIN_QUERY_FIELD,
  USER_UPDATE_QUERY_FIELD,
  REQUEST_OTP,
  REQUEST_OTP_QUERY_FIELD
} from '../../graphql/gql/mutation/auth';
import { USER, USER_QUERY_FIELD } from '../../graphql/gql/query/auth';
import { ALERT_MSG_TYPE, REDIRECT_URL_AFTER_LOGIN, PREV_URL, ROUTES } from "../../constants";
import { getResponseFrom } from '../../Utils';
import {
  LOGIN as LOGIN_TYPE,
  USER as USER_TYPE, LOADER,
  SHOW_NOTIFICATION,
  UPDATE_USER as UPDATE_USER_TYPE
} from '../constants';

const client = getClient();

const dispatchAction = (type, payload) => {
  return {
    type, payload
  }
};

export const requestOtp = (number, { history, state }) => {
  return (dispatch) => {
    dispatch(dispatchAction(LOADER, true));

    client
      .mutate({
        mutation: REQUEST_OTP,
        variables: { number }
      })
      .then((res) => getResponseFrom(res, REQUEST_OTP_QUERY_FIELD))
      .then(res => {
        const { statusCode } = res[REQUEST_OTP_QUERY_FIELD];

        if (statusCode === 200) {
          localStorage.setItem(
            REDIRECT_URL_AFTER_LOGIN,
            localStorage.getItem(PREV_URL)
          );
          history?.push({
            pathname: ROUTES.verifyOtp,
            state: {
              number,
              ...state
            },
          });
          dispatch(dispatchAction(LOADER, false));
        }
      })
      .catch(error => {
        dispatch(dispatchAction(LOADER, false));
        dispatch(dispatchAction(SHOW_NOTIFICATION, { show: true, message: error, msgType: ALERT_MSG_TYPE.ERROR }))
      });
  }
};

export const login = (payload, eventCallBack) => {
  return (dispatch) => {
    dispatch(dispatchAction(LOADER, true));

    client
      .mutate({
        mutation: LOGIN,
        variables: {
          ...payload,
          role: "",
        },
      })
      .then((res) => getResponseFrom(res, LOGIN_QUERY_FIELD))
      .then((res) => {
        const { data, message } = res[LOGIN_QUERY_FIELD];
        if (eventCallBack) eventCallBack(data);
        dispatch(dispatchAction(LOADER, false));
        dispatch(dispatchAction(LOGIN_TYPE, data));
        dispatch(dispatchAction(SHOW_NOTIFICATION, { show: true, message, msgType: ALERT_MSG_TYPE.SUCCESS }));
      }).catch(err => {
        dispatch(dispatchAction(SHOW_NOTIFICATION, { show: true, message: err, msgType: ALERT_MSG_TYPE.ERROR }));
        dispatch(dispatchAction(LOADER, false));
      });
  };
};

export const getUser = () => {
  return (dispatch) => {
    client
      .query({
        query: USER,
        fetchPolicy: "network-only",
      })
      .then((res) => {
        if (!res?.data?.currentUser) {
          dispatch(dispatchAction(LOADER, false));
        }
        return getResponseFrom(res, USER_QUERY_FIELD);
      })
      .then(res => {
        const currentUser = res[USER_QUERY_FIELD];
        dispatch(dispatchAction(USER_TYPE, currentUser));
        dispatch(dispatchAction(LOADER, false));
      });
  };
};

export const updateUser = (payload) => {
  return (dispatch) => {
    client
      .mutate({
        mutation: UPDATE_USER,
        variables: {
          ...payload,
        },
        refetchQueries: [
          {
            query: USER,
            fetchPolicy: "network-only",
          },
        ],
      })
      .then((res) => getResponseFrom(res, USER_UPDATE_QUERY_FIELD))
      .then((res) => {
        const { data, message } = res[USER_UPDATE_QUERY_FIELD];
        dispatch(dispatchAction(UPDATE_USER_TYPE, data));
        dispatch(dispatchAction(SHOW_NOTIFICATION, { show: true, message, msgType: ALERT_MSG_TYPE.SUCCESS }));
      }).catch(err => {
        dispatch(dispatchAction(SHOW_NOTIFICATION, { show: true, message: err, msgType: ALERT_MSG_TYPE.ERROR }));
        dispatch(dispatchAction(LOADER, false));
      });
  };
};
