import { ApolloLink } from "apollo-link";
import {
  getCookie,
  getDeviceDetailsHeaders,
  getJWT,
  getUtmParameters,
  isWebView,
} from "../../Utils";

export const kmheaders = new ApolloLink((operation, forward) => {
  const isCurrentSessionInWebView = isWebView();
  const storedJWTToken = getJWT();
  const kmHeaders = {
    "X-CSRF-TOKEN": getCookie("XSRF-TOKEN"),
    "x-km-utm-parameters": getUtmParameters(),
    ...getDeviceDetailsHeaders(),
  };

  if (isCurrentSessionInWebView && storedJWTToken) {
    kmHeaders["Auth-Strategy"] = "jwt";
    kmHeaders["Authorization"] = storedJWTToken;
  }

  // add the recent-activity custom header to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...kmHeaders,
    },
  }));

  return forward(operation);
});
