import { ROUTES, SCREEN_NAME } from "./constants";
/* eslint-disable no-tabs */
import loadable from "@loadable/component";

const routes = [
  // {
  //   url: ROUTES.root,
  //   component: loadable(() => import("./containers/CategoryContainer")),
  //   name: SCREEN_NAME.DASHBOARD,
  //   key: 1,
  // },
  {
    url: ROUTES.wellnessUI,
    component: loadable(() =>
      import("./components/Category/wellnessAndMore/wellnessUI")
    ),
    name: SCREEN_NAME.WellnessAndMore,
  },
  {
    url: ROUTES.login,
    component: loadable(() => import("./components/Login/PhoneNumberSlide")),
    name: SCREEN_NAME.phoneLogin,
  },
  {
    url: ROUTES.verifyOtp,
    component: loadable(() => import("./components/Login/PhoneOtpSlide")),
    name: SCREEN_NAME.verifyOtp,
  },
  {
    url: ROUTES.personalDetails,
    component: loadable(() => import("./components/Login/PersonalDetails")),
    name: SCREEN_NAME.personalDetails,
  },
  {
    url: ROUTES.myProfile,
    component: loadable(() => import("./components/MyProfile")),
    name: SCREEN_NAME.myProfile,
    isPrivate: true,
  },
  {
    url: ROUTES.courseDetails,
    component: loadable((props) =>
      import("./containers/CourseDetailContainer")
    ),
    name: SCREEN_NAME.courseDetails,
  },
  {
    url: ROUTES.bookingReview,
    component: loadable(() => import("./containers/BookingReviewContainer")),
    name: SCREEN_NAME.bookingReview,
    isPrivate: true,
  },
  {
    url: ROUTES.slotSelection,
    component: loadable(() => import("./containers/SlotSelectionContainer")),
    name: SCREEN_NAME.slotSelection,
  },
  {
    url: ROUTES.confirmationDetails,
    component: loadable(() =>
      import("./containers/ConfirmationDetailContainer")
    ),
    name: SCREEN_NAME.confirmationDetails,
  },
  {
    url: ROUTES.bookings,
    component: loadable(() => import("./containers/MyBookingContainer")),
    name: SCREEN_NAME.bookings,
    isPrivate: true,
  },
  {
    url: ROUTES.credits,
    component: loadable((props) => import("./containers/WalletContainer")),
    name: "km-credit",
    isPrivate: true,
  },
  {
    url: "/credits/view-all",
    component: loadable((props) =>
      import("./containers/ListOfTransactionContainer")
    ),
    name: "km-all-credit",
    isPrivate: true,
  },
  {
    url: ROUTES.batches,
    component: loadable(() => import("./containers/CourseBatchesContainer")),
    name: SCREEN_NAME.batchPage,
  },
  {
    url: ROUTES.slots,
    component: loadable(() => import("./containers/SlotPageContainer")),
    name: SCREEN_NAME.SlotPage,
  },
  {
    url: ROUTES.onBehalfReview,
    component: loadable((props) =>
      import("./components/OnBehalf/BookingReview")
    ),
    name: "onBehalf-booking-review",
    isPrivate: true,
  },
  {
    url: ROUTES.onBehalfConfirmation,
    component: loadable((props) =>
      import("./components/OnBehalf/BookingConfirmation")
    ),
    name: "onBehalf-booking-confirmation",
    isPrivate: true,
  },
  {
    url: ROUTES.root,
    component: loadable(() =>
      import("./components/NewAtHome/NewHomePage/NewHomePage")
    ),
    name: "new-home-page",
  },
  {
    url: "/sports-venue-list/:cityId",
    component: loadable(() =>
      import("./components/NewAtHome/SocietyListing/SocietyListing")
    ),
    name: "society-list",
  },
  {
    url: "/society/:societyName/:cityName/:societyId",
    component: loadable(() =>
      import("./components/NewAtHome/SocietyDetails/SocietyDetails")
    ),
    name: "society-details",
  },
  {
    url: "/new-at-home/slot-selection/:societyName/:sportName",
    component: loadable(() =>
      import("./components/NewAtHome/SlotPage/SlotPageContainer")
    ),
    name: "slot-selection",
  },
  {
    url: "/free-trials-batches/:sportName/:societyId",
    component: loadable(() =>
      import("./components/NewAtHome/MultipleFreeTrials")
    ),
    name: "multiple-free-trials",
  },
  {
    url: "/new-at-home/batch-details/:batchId",
    component: loadable(() => import("./components/NewAtHome/BatchDetails")),
    name: "batch-details",
  },
  {
    url: "/new-at-home/search",
    component: loadable(() =>
      import("./components/NewAtHome/SearchPage/index")
    ),
    name: "search-page",
  },
  {
    url: "/new-at-home/booking-review/:bookingId",
    component: loadable(() =>
      import("./components/NewAtHome/BookingReview/index")
    ),
    name: "new-at-home-booking-review",
  },
  {
    url: "/reviews-ratings/:bookingId",
    component: loadable(() =>
      import("./components/NewAtHome/ReviewsAndRatings")
    ),
    name: "new-at-home-reviews-ratings",
  },
  {
    url: "/new-at-home/booking-confirmed/:id",
    component: loadable(() =>
      import("./components/NewAtHome/BookingConfirmed/index")
    ),
    name: "new-at-home-booking-confirmed",
  },
  {
    url: "/get-a-coach/:sportName",
    component: loadable((props) =>
      import("./components/FindACoach/SportDetails/SportDetails")
    ),
    name: "sport-details",
    isPrivate: false,
  },
  {
    url: "/get-a-coach-participant-details",
    component: loadable((props) =>
      import("./components/FindACoach/ParticipantDetails/ParticipantDetails")
    ),
    name: "participant-details",
    isPrivate: false,
  },
  {
    url: "/get-a-coach-booking-review",
    component: loadable((props) =>
      import("./components/FindACoach/BookingReview/BookingReview")
    ),
    name: "booking-review",
    isPrivate: false,
  },
  {
    url: "/get-a-coach-booking-confirmed/:id",
    component: loadable((props) =>
      import("./components/FindACoach/BookingConfirmed/BookingConfirmed")
    ),
    name: "booking-confirmed",
    isPrivate: false,
  },
  {
    url: "/get-a-coach-slot-slection/:sportName",
    component: loadable((props) =>
      import("./components/FindACoach/SlotSlection/SlotSlection")
    ),
    name: "slot-slection",
    isPrivate: true,
  },
  {
    url: "/wellness-session-bookings",
    component: loadable((props) =>
      import("./components/MyBookings/WellnessBookings/coachingBookings")
    ),
    name: "wellness-session-bookings",
    isPrivate: true,
  },
  {
    url: "/society-sports-coaching",
    component: loadable((props) =>
      import("./components/NewAtHome/RegisterSociety/RegisterSociety")
    ),
    name: "society-sports-coaching",
    isPrivate: false,
  },
];

export default routes;
