import React from "react";
import { analyticsTrackingScriptsGetters } from "../../analytics";

class TrackingScripts extends React.PureComponent {
  componentDidMount() {
    this.renderTrackingScripts();
  }

  renderTrackingScripts = () => {
    const analyticsScriptContainer = document.getElementById(
      "analytics-scripts-container"
    );

    if (analyticsScriptContainer) {
      // Clear the content inside the Analytics Scripts Container div
      analyticsScriptContainer.innerHTML = "";

      // Iterate over all script getters and append them in the div
      analyticsTrackingScriptsGetters.map((scriptGetter) => {
        if (typeof scriptGetter === "function") {
          const trackingScript = scriptGetter();
          const trackerScriptTag = document.createElement("script");
          trackerScriptTag.text = trackingScript;
          analyticsScriptContainer.appendChild(trackerScriptTag);
        }
      });
    }
  };

  render() {
    return null;
  }
}

export default TrackingScripts;
