import { SEGMENT_API_KEY, CLEVER_TAP_ACCOUNT_ID, FB_PIXEL_ID } from "../config";

// export const getSegmentTrackingKey = () => {
//   return `
//     !function(){var analytics=window.analytics=window?.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var t=analytics.methods[e];analytics[t]=analytics.factory(t)}analytics.load=function(e,t){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+e+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a?.parentNode.insertBefore(n,a);analytics._loadOptions=t};analytics.SNIPPET_VERSION="4.1.0";
//     analytics.load("${SEGMENT_API_KEY}");
//     analytics.page();
//     }}();
//   `;
// };

export const getCleverTapScript = () => {
  return `
    var clevertap = {event:[], profile:[], account:[], onUserLogin:[], notifications:[], privacy:[]};
    clevertap.account.push({"id": '${CLEVER_TAP_ACCOUNT_ID}'});
    clevertap.privacy.push({optOut: false}); //set the flag to true, if the user of the device opts out of sharing their data
    clevertap.privacy.push({useIP: false}); //set the flag to true, if the user agrees to share their IP data
    (function () {
        var wzrk = document.createElement('script');
        wzrk.type = 'text/javascript';
        wzrk.async = true;
        wzrk.src = ('https:' == document.location.protocol ? 'https://d2r1yp2w7bby2u.cloudfront.net' : 'http://static.clevertap.com') + '/js/a.js';
        var s = document.getElementsByTagName('script')[0];
        s?.parentNode.insertBefore(wzrk, s);
      })();
  `;
};

export const getFacebookSDKScript = () => {
  return `
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s?.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '${FB_PIXEL_ID}');
  fbq('track', 'PageView');`;
};

export const getWhatsappSDKScript = () => {
  return `
  (function (w, d, s, c, r, a, m) {
    w["KiwiObject"] = r;
    w[r] =
      w[r] ||
      function () {
        (w[r].q = w[r].q || []).push(arguments);
      };
    w[r].l = 1 * new Date();
    a = d.createElement(s);
    m = d.getElementsByTagName(s)[0];
    a.async = 1;
    a.src = c;
    m.parentNode.insertBefore(a, m);
  })(
    window,
    document,
    "script",
    "https://app.interakt.ai/kiwi-sdk/kiwi-sdk-17-prod-min.js?v=" +
      new Date().getTime(),
    "kiwi"
  );
  window.addEventListener("load", function () {
    kiwi.init("", "6zE8AFs1BZOVlE7oRRiXXskBztMDfuOT", {});
  });`;
};
