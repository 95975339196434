import { gql } from "apollo-boost";

export const REQUEST_OTP = gql`
  mutation RequestOtp($number: String!) {
    requestOtp(mobileNumber: $number) {
      statusCode
      message
      success
      data
    }
  }
`;

export const LOGIN = gql`
  mutation Login($number: String!, $otp: String!, $role: String!) {
    login(mobileNumber: $number, otp: $otp, role: $role) {
      statusCode
      message
      success
      data {
        id
        mobile
        name
        email
        createdOn
        role
        longitude
        latitude
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UserInput) {
    updateUser(input: $input) {
      statusCode
      message
      success
      data {
        id
        name
        email
        mobile
        role
        dob
        gender
        city
      }
    }
  }
`;

export const LOGIN_QUERY_FIELD = "login";
export const USER_UPDATE_QUERY_FIELD = "updateUser";
export const REQUEST_OTP_QUERY_FIELD = "requestOtp";
