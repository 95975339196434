import { gql } from "apollo-boost";

export const USER = gql`
  query CurrentUser {
    currentUser {
      id
      name
      email
      mobile
      role
      dob
      gender
      city
      isSplitPaymentDisabled
      isCreditsDisabled
    }
  }
`;

export const USER_QUERY_FIELD = "currentUser";
