const appConfig = window.__KM_APP_CONFIG__;

export const API_URL = appConfig.REACT_APP_API_URL;

export const { RESEND_OTP_TIMER } = appConfig;

export const MAP_API_KEY = appConfig.REACT_APP_MAP_API_KEY;

export const SEGMENT_API_KEY = appConfig.REACT_APP_SEGMENT_TRACKING_KEY;

export const APP_VERSION = appConfig.REACT_APP_APP_VERSION;

export const APP_NAME = appConfig.REACT_APP_APP_NAME;

export const { PUBLIC_URL } = appConfig;

export const CLEVER_TAP_ACCOUNT_ID = appConfig.REACT_APP_CLEVER_TAP_ACCOUNT_ID;

export const FB_PIXEL_ID = appConfig.REACT_APP_FB_PIXEL_ID;

export const BASE_URL = appConfig.REACT_APP_BASE_URL;

export const MAIN_URL = appConfig.REACT_APP_MAIN_URL;

export const ERROR_TRACK_API = appConfig.REACT_APP_ERROR_TRACK_API;

export const ERROR_TRACK_AUTH_KEY = appConfig.REACT_APP_ERROR_TRACK_AUTH_KEY;

export const PNP_BASE_URL = appConfig.REACT_APP_PNP_URL;
