import { ERROR_TRACK_API, ERROR_TRACK_AUTH_KEY } from "../../config";
import { getUADetails } from "../../Utils";

function updateErrorMessage({
  message,
  api,
  parameters,
  response_body,
  stack,
}) {
  const timeStamp = new Date().valueOf();
  fetch(ERROR_TRACK_API, {
    method: "POST",
    body: JSON.stringify({
      device_details: getUADetails(),
      timestamp: timeStamp,
      request_details: {
        api: api,
        hostname: window?.location?.hostname,
        headers: {},
        parameters: parameters,
      },
      response_details: {
        headers: {},
        response_body: response_body,
      },
      error_details: {
        message: message,
        stack_trace: stack,
      },
    }),
    headers: {
      "Content-Type": "application/json",
      X_KM_AUTH: ERROR_TRACK_AUTH_KEY,
    },
  }).then((response) => response.json());
}

export default updateErrorMessage;
